<template>
    <div class="details-box">
        <!-- TAB -->
        <el-tabs v-model="activeName">
            <el-tab-pane label="个人信息表" name="personal">
                <Info v-if="activeName === 'personal'" />
            </el-tab-pane>
            <!-- <el-tab-pane label="我的评分" name="rating">
                <Rating v-if="activeName === 'rating'" />
            </el-tab-pane> -->
        </el-tabs>
    </div>
</template>

<script>
import Info from '../../../components/Details/MacaoGifted/Info.vue'
import Rating from '../../../components/Details/MacaoGifted/Rating.vue'
export default {
    name: 'MacaoGiftedDetails',
    components: {
        Info, Rating
    },
    data() {
        return {
            activeName: 'personal',
        }
    },
    methods: {
    },
}
</script>

<style lang="less" scoped>

</style>
